var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customizer d-none d-md-block",class:{'open': _vm.isCustomizerOpen}},[_c('b-link',{staticClass:"customizer-toggle d-flex align-items-center justify-content-center",on:{"click":function($event){_vm.isCustomizerOpen = !_vm.isCustomizerOpen}}},[_c('feather-icon',{staticClass:"spinner",attrs:{"icon":"SettingsIcon","size":"15"}})],1),_c('div',{staticClass:"customizer-section d-flex justify-content-between align-items-center"},[_c('div',[_c('h4',{staticClass:"text-uppercase mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Customizer.ThemeCustomizer'))+" ")])]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"18"},on:{"click":function($event){_vm.isCustomizerOpen = !_vm.isCustomizerOpen}}})],1),_c('vue-perfect-scrollbar',{staticClass:"ps-customizer-area scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings}},[_c('div',{staticClass:"customizer-section"},[_c('b-form-group',{attrs:{"label":_vm.$t('Customizer.Skin')}},[_c('b-form-radio-group',{attrs:{"id":"skin-radio-group","name":"skin"},model:{value:(_vm.skin),callback:function ($$v) {_vm.skin=$$v},expression:"skin"}},_vm._l((_vm.skinOptions),function(ref,index){
var text = ref.text;
var value = ref.value;
return _c('b-form-radio',{key:("skin_" + index),attrs:{"value":value,"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t(("Customizer.Skins." + text)))+" ")])}),1)],1),_c('sw-select',{attrs:{"name":_vm.$t('Customizer.AnimationOfTheView'),"label-cols":"6"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"label":"title","options":_vm.routerTransitionOptions,"reduce":function (option) { return option.value; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true},{key:"option",fn:function(ref){
var title = ref.title;
return [_vm._v(" "+_vm._s(_vm.$t(("Customizer.Animations." + title)))+" ")]}},{key:"selected-option",fn:function(ref){
var title = ref.title;
return [_vm._v(" "+_vm._s(_vm.$t(("Customizer.Animations." + title)))+" ")]}}]),model:{value:(_vm.routerTransition),callback:function ($$v) {_vm.routerTransition=$$v},expression:"routerTransition"}})],1)],1),_c('div',{staticClass:"customizer-section"},[(_vm.layoutType === 'vertical')?_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('Customizer.NavbarCollapsed')))]),_c('b-form-checkbox',{staticClass:"mr-0",attrs:{"variant":"primary","name":"is-vertical-menu-collapsed","switch":"","inline":""},model:{value:(_vm.isVerticalMenuCollapsed),callback:function ($$v) {_vm.isVerticalMenuCollapsed=$$v},expression:"isVerticalMenuCollapsed"}})],1):_vm._e()]),_c('div',{staticClass:"customizer-section"},[_c('b-form-group',{attrs:{"label":_vm.$t('Customizer.NavbarType')}},[_c('b-form-radio-group',{attrs:{"name":"navbar-type"},model:{value:(_vm.navbarType),callback:function ($$v) {_vm.navbarType=$$v},expression:"navbarType"}},_vm._l((_vm.navbarTypes),function(item,index){return _c('div',{key:("navbar_type_" + index),staticClass:"mt-25"},[(item)?_c('b-form-radio',{attrs:{"variant":"primary","value":item.value}},[_vm._v(" "+_vm._s(_vm.$t(("Customizer.Types." + (item.text))))+" ")]):_vm._e()],1)}),0)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }